<template>
  <div class="page-section container">
    <page-separator title="" />
    <b-alert show variant="warning"
      >To prevent any potential payment inconveniences, it is strongly advised not to navigate away from this page if
      your payment is in progress until process is completed.</b-alert
    >
    <b-form @submit.prevent="pay">
      <div :class="!isMobileScreen ? 'row' : ''">
        <div :class="!isMobileScreen ? 'col-7' : ''">
          <div class="card">
            <div class="card-header bg-light">
              <b-skeleton v-if="isLoading" width="50%"></b-skeleton>
              <h5 v-else class="my-0">Plan Summary</h5>
            </div>
            <div class="card-body">
              <b-skeleton-wrapper :loading="isLoading">
                <template #loading>
                  <p class="lead text-white-50">
                    <b-skeleton width="70%"></b-skeleton>
                  </p>
                  <p class="lead text-white-50">
                    <b-skeleton width="40%"></b-skeleton>
                  </p>
                  <p class="lead text-white-50">
                    <b-skeleton width="55%"></b-skeleton>
                  </p>
                </template>
                <div>
                  <div class="mb-1"><strong>Program: </strong>{{ plan.program_name }}</div>
                  <div class="mb-1"><strong>Class: </strong>#{{ plan.linked_class }}</div>
                  <div class="mb-1"><strong>Installment Due Date: </strong>{{ formatFullDate(plan.date) }}</div>
                  <div class="mb-1" v-if="plan.status_paid">
                    <strong>Installment Paid Date: </strong>{{ formatFullDate(plan.date_paid) }}
                  </div>
                </div>
              </b-skeleton-wrapper>
            </div>
          </div>
        </div>
        <div :class="!isMobileScreen ? 'col-5' : ''">
          <div class="card">
            <div class="card-header bg-light">
              <b-skeleton v-if="isLoading" width="50%"></b-skeleton>
              <h5 v-else class="my-0">{{ plan.status_paid ? 'Amount' : 'Amount Due' }}</h5>
            </div>
            <div class="card-body">
              <b-skeleton-wrapper :loading="isLoading">
                <template #loading>
                  <p class="lead text-white-50">
                    <b-skeleton width="40%"></b-skeleton>
                  </p>
                  <p class="lead text-white-50">
                    <b-skeleton width="35%"></b-skeleton>
                  </p>
                  <p class="lead text-white-50">
                    <b-skeleton width="50%"></b-skeleton>
                  </p>
                </template>
                <div>
                  <div class="mb-1"><strong>Installment Amount: </strong>${{ $n(plan.amount) }}</div>
                  <div class="mb-1"><strong>Online Service Fee: </strong>${{ $n(calcServiceFee) }}</div>
                  <div class="mb-1">
                    <strong>{{ plan.status_paid ? 'Total Amount Paid' : 'Total Amount Due' }}: </strong>${{
                      $n(calcServiceFee + plan.amount)
                    }}
                  </div>
                  <div v-if="plan.status_paid">
                    <strong
                      >Payment Status:
                      <span class="text-success" style="font-size: 15px">
                        {{ plan.status_paid ? 'Paid' : '' }}
                      </span></strong
                    >
                  </div>
                </div>
              </b-skeleton-wrapper>
            </div>
          </div>
        </div>
      </div>

      <div class="card" v-if="!plan.status_paid">
        <div class="card-header bg-light">
          <b-skeleton v-if="isLoading" width="50%"></b-skeleton>
          <h5 v-else class="my-0">Payment Information</h5>
        </div>
        <div class="card-body">
          <b-skeleton-wrapper :loading="isPaymentDetailsLoading || isPaymentMethodLoading || isLoading">
            <template #loading>
              <p class="lead text-white-50">
                <b-skeleton width="70%"></b-skeleton>
              </p>
              <p class="lead text-white-50">
                <b-skeleton width="60%"></b-skeleton>
              </p>
              <p class="lead text-white-50">
                <b-skeleton width="50%"></b-skeleton>
              </p>
            </template>
          </b-skeleton-wrapper>

          <div v-if="paymentMethod && !isLoading">
            <span>
              A payment of <b>${{ $n(calcServiceFee + plan.amount) }}</b> will be billed to

              <b>card ending with {{ get(paymentMethod, 'last_four') }}</b
              >. Click
              <router-link class="text-primary" :to="{ name: 'manage-payment-method' }" style="font-weight: bold">
                here </router-link
              >to update you payment method.
            </span>
            <div class="mt-1"><b>Note:</b> Any additional fees found above may apply.</div>
          </div>
          <stripe-element-payment
            v-if="!paymentMethod && showPaymentDetails"
            ref="paymentRef"
            :pk="pk"
            :testMode="testMode"
            :elements-options="elementsOptions"
            :confirm-params="confirmParams"
            @loading="(value) => (isPayLoading = value)"
            @element-ready="() => (isPaymentDetailsLoading = false)"
          />
        </div>
      </div>
      <div class="d-flex justify-content-end mt-2" v-if="!plan.status_paid">
        <b-skeleton type="button" v-if="isLoading || isPaymentDetailsLoading" width="150px"></b-skeleton>
        <b-btn v-else variant="primary" class="ml-2" style="width: 150px" type="submit" :disabled="isPayLoading">
          <i v-if="isPayLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>{{ 'pay now' }}</span></b-btn
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { get, isEmpty } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { formatDateTime, formatFullDate } from '@/common/utils';
import { StripeElementPayment } from '@vue-stripe/vue-stripe';
import { STD_REG_MIN_SERVICE_FEE } from '@/common/constants';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  components: { StripeElementPayment, PageSeparator },

  props: {
    plan: { type: Object, default: null },
    isLoading: { type: Boolean, default: true },
  },

  data() {
    return {
      windowWidth: window.innerWidth,

      pk: process.env.VUE_APP_HE_STRIPE_PUBLISHABLE_KEY,
      testMode: process.env.VUE_APP_TEST_MODE === 'true',
      elementsOptions: {
        appearance: { theme: 'stripe' }, // appearance options
        // 'clientSecret' being added below.
      },
      isPayLoading: false,
      paymentMethod: null,
      isPaymentMethodLoading: false,
      confirmParams: {},
      showPaymentDetails: false,
      isPaymentDetailsLoading: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getCurrentSchool']),

    isMobileScreen() {
      return this.windowWidth <= 576;
    },
    calcServiceFee() {
      // Healthcare Enroll Service Fee = 4% of total transaction fee.
      // Minimum service fee is $2.99
      const serviceFee = this.plan.amount * 0.04;

      return serviceFee > STD_REG_MIN_SERVICE_FEE ? serviceFee : STD_REG_MIN_SERVICE_FEE;
    },
  },
  methods: {
    ...mapActions('payments', ['createPaymnetPlanIntent', 'getDefaultPaymentMethod']),
    formatDateTime,
    formatFullDate,
    get,

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    async pay() {
      if (this.paymentMethod) {
        this.createPaymnetIntent();
        window.location.href = `${window.location.origin}/student/manage-plans/${this.$route.params.id}?checkoutSuccess=1`;
      } else {
        this.$refs.paymentRef.submit();
      }
    },
    async fetchPaymentMethod() {
      this.isPaymentMethodLoading = true;
      try {
        this.paymentMethod = get((await this.getDefaultPaymentMethod()).data, 'data');
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isPaymentMethodLoading = false;
    },
    async createPaymnetIntent() {
      if (!this.paymentMethod) {
        this.isPaymentDetailsLoading = true;
      }

      try {
        const paymentIntent = await this.createPaymnetPlanIntent({
          plan_id: this.$route.params.id,
        });

        if (paymentIntent.data.intent) {
          this.elementsOptions['clientSecret'] = paymentIntent.data.intent;

          this.confirmParams[
            'return_url'
          ] = `${window.location.origin}/student/manage-plans/${this.$route.params.id}?checkoutSuccess=1`;
          this.showPaymentDetails = true;
        }
      } catch (e) {
        await this.makeToast({ variant: 'danger', msg: 'Some error has occured. Try again later.' });
        setTimeout(() => {
          this.$router.push({ name: 'manage-plans' });
        }, 300);
      }
    },
    async fetchPaymentCreateIntent(value) {
      await this.fetchPaymentMethod();

      if (!this.paymentMethod && !value.status_paid) {
        this.createPaymnetIntent();
      }
    },
  },
  watch: {
    plan: {
      immediate: true,
      handler(value) {
        if (!isEmpty(value)) {
          this.fetchPaymentCreateIntent(value);
        }
      },
    },
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style></style>
